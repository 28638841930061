import React from 'react';
import withStyles from 'material-ui/styles/withStyles';
/*
  Additional Resources Page
*/
const styles = (theme) => ({});

const CONTACT = ({}) => {
  return (
    <div>
      <div>
        <a href='https://nordstrom.sharepoint.com/sites/NordstromSupplierCompliance/SitePages/Home.aspx'>
          Nordstrom Supplier Compliance
        </a>
      </div>
      <br />
      <div>
        <a href='/static/media/Setup_Guide.3cc9bc5f.pdf' download>
          Supplier Compliance Setup Guide
        </a>
      </div>
      <br />
      <div>
        <a href='https://portal.nordstrom.com/pwred-pub/access/Login.do'>
          AP Vendor Inquiry
        </a>
      </div>
    </div>
  );
};
export default withStyles(styles)(CONTACT);
